<template>
  <div class="braceletHome">
    <div class="topTitle">
      <img :src="avatarUrl" alt="">
      <div @click="goTo('/myProfile')" class="info">
        <div class="name" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{ userInfo ? userInfo.nickname : '' }}</div>
        <div class="id">ID : {{ userInfo ? userInfo.id : '' }}</div>
      </div>
      <img class="navto1" @click="show = true"
           src="@/assets/bracelet_img/lang.png"
           alt="">
      <img class="navto" @click="goTo('/navbar')"
           src="@/assets/bracelet_img/navto.png"
           alt="">
    </div>

    <img class="tupian" src="@/assets/bracelet_img/21.png" alt="">

    <div class="box">

      <div class="rank_integral">
        <div class="top">
          <div class="one">
            <div class="rank" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('排行')}}</div>
          </div>
          <div class="two">
            <div class="integral" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('积分')}}</div>
          </div>
        </div>
        <div class="btm">
          <div class="left">{{ userInfo.total_consume_ranking > 100 ? '100+' : userInfo.total_consume_ranking }}</div>
          <div class="right">{{ userInfo?.total_consume ?? 0 }}</div>
        </div>
      </div>

      <div class="distance">
        <div class="status" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('探索距离')}}</div>
        <img class="bg"
             src="@/assets/bracelet_img/23.png"
             alt="">
        <div class="main">
          <div @click="goTo('/stepsNumber')"
               class="item">
            <div class="num">{{ motionData?.steps ?? 0 }}</div>
            <div class="row">
              <img src="@/assets/bracelet_img/17.png"
                   alt="">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('步数')}}</div>
            </div>
          </div>
          <div @click="goTo('/heartRate')"
               class="item">
            <div class="num">{{ motionData?.heart_rate ?? 0 }}</div>
            <div class="row">
              <img src="@/assets/bracelet_img/15.png"
                   alt="">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('心率')}}(BPM)</div>
            </div>
          </div>
          <div @click="goTo('/calorie')"
               class="item">
            <div class="num">{{ motionData?.calories ?? 0 }}</div>
            <div class="row">
              <img src="@/assets/bracelet_img/16.png"
                   alt="">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('卡路里')}}(KCAL)</div>
            </div>
          </div>
          <div class="item">
            <div class="num">{{ motionData?.duration ?? 0 }}</div>
            <div class="row">
              <img src="@/assets/bracelet_img/19.png"
                   alt="">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('累计时长(分钟)')}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="datePreset">
        <div class="status" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('日期预设')}}</div>
        <div class="box">
          <div class="calendar">
            <div class="braceletHomeTitle">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{ month }}{{$t('月')}}{{ year }}</div>
              <img @click="dateShow = true"
                   class="pointer"
                   src="@/assets/bracelet_img/25.png"
                   alt="">
            </div>
            <Calendar ref="Calendar"
                      :textTop="['SU','MO', 'TU', 'WE', 'TH', 'FR', 'SA']"
                      :sundayStart='true'
                      v-on:choseDay="clickDay"></Calendar>
          </div>
        </div>

        <div class="time">
          <div class="braceletHomeTitle" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{ month }}{{$t('月')}}{{ day }}{{$t('日')}} {{ year }}</div>
          <div class="item">
            <div class="line"></div>
            <div class="right">
              <div class="text" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('户外跑步总记录')}}</div>
              <div class="dt" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{ allSteps }}({{$t('步数')}})</div>
            </div>
          </div>
        </div>
        <div @click="goTo('/activityReport')"
             class="report" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('查看活动报告')}}></div>
      </div>
    </div>

    <div class="footBtm">
      <div class="left">
        <div class="box">
          <img src="@/assets/bracelet_img/5.png"
               alt="">
        </div>
      </div>
      <div class="right">
        <div @click="goTo('/market')"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             class="ask">{{$t('交易市场')}}</div>
        <div @click="goTo('/roomHome')"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             class="invite">{{$t('社交挖矿')}}</div>
      </div>
    </div>

    <!-- 月份弹出层 -->
    <van-popup v-model="dateShow"
               position="bottom">
      <van-datetime-picker v-model="currentDate"
                           @confirm="confirmDate"
                           @cancel="cancelDate"
                           :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                           :confirm-button-text="$t('确定')"
                           :cancel-button-text="$t('取消')"
                           type="year-month"
                           :title="$t('选择年月')" />
    </van-popup>

    <van-popup class="pop-up"
               v-model="show">
      <div class="pop-up-box">
        <van-radio-group v-model="radio"
                         checked-color="#7c4283">
          <van-radio class="pop-box-radio"
                     name="cn">中文</van-radio>
          <van-radio class="pop-box-radio"
                     name="en">English</van-radio>
          <van-radio class="pop-box-radio"
                     name="ko">한국어</van-radio>
          <van-radio class="pop-box-radio"
                     name="vi">ViệtName</van-radio>
          <van-radio class="pop-box-radio"
                     name="ms">Malay</van-radio>
          <van-radio class="pop-box-radio"
                     name="id">IndonesiaName</van-radio>
        </van-radio-group>
      </div>
      <div class="pop-up-btn">
        <button class="pop-btn-btn btn-cancel-col"
                @click="cancel">{{$t('取消')}}</button>
        <button class="pop-btn-btn btn-determine-col"
                @click="setLang">{{$t('确定')}}</button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { userInfo, Sportdata, getDateInfo, language } from '@/api/api'
import { mapState } from 'vuex'
import Calendar from "vue-calendar-component";
import positiveTime from '@/components/positiveTime/index.vue'
export default {
  components: {
    Calendar,
    positiveTime
  },
  data () {
    return {
      userInfo: {},
      avatarUrl: '',
      currentDate: '',
      dateShow: false,
      timer: null,
      year: new Date().getFullYear(), //获取完整的年份(4位)
      month: new Date().getMonth() + 1, //获取当前月份(0-11,0代表1月)
      day: new Date().getDate(), //获取当前日(1-31)
      sportData: {
        heart_rate: '',
        steps: '',
        calorie: '',
        duration: '',
        durable: '',
        top: '',
      },
      motionData: {
        heart_rate: '',
        steps: '',
        calorie: '',
        duration: '',
        durable: '',
        top: '',
      },
      allSteps: 0,
      show: false,
      radio: '',
    }
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () { this.waitForGlobal(); },
  methods: {
    getPlanInfo (date) {
      getDateInfo({ date }).then(res => {
        if (res.code == 0) {
          this.allSteps = res?.data?.steps ?? 0
        }
      })
    },
    
    /* 获取当次运动数据 */
    getSportdata () {
      Sportdata().then(res => {
        if (res.code == 0) {
          this.motionData = res.data
        }
      })
    },
    
    getUserInfo () {
      userInfo({}, localStorage.getItem('Token')).then(res => {
        if (res.code == 0) {
          window.localStorage.setItem('userInfo', JSON.stringify(res.data))
          window.localStorage.setItem('langue', res.data.langue)
          this.userInfo = res.data
          this.avatarUrl = res.data.avatar
        }
      })
    },
    /* 多语言弹窗打开 */
    setLang () {
      if (this.radio.length == '') {
        return
      }
      /* 设置多语言 */
      language({ lang: this.radio }).then(res => {
        if (res.code == 0) {
          localStorage.setItem('langue', this.radio)
          this.$i18n.locale = this.radio
          this.show = false
          this.radio = ''
          // this.$router.go(0)
          location.reload();
        }
      })
    },
    /* 多语言弹窗关闭 */
    cancel () {
      this.show = false
      this.radio = ''
    },
    goTo (url) {
      this.$router.push({
        path: url
      })
    },
    clickDay (data) {
      let date = data.split('/')
      this.year = date[0]
      this.month = date[1]
      this.day = date[2]

      let time, year, month, day;
      year = date[0]
      month = date[1] < 10 ? '0' + date[1] : date[1]
      day = date[2] < 10 ? '0' + date[2] : date[2]
      time = year + '-' + month + '-' + day
      this.getPlanInfo(time);
    },
    cancelDate () {
      this.dateShow = false
    },
    confirmDate (data) {
      let d = new Date(data);
      let y = d.getFullYear();
      let m = d.getMonth() + 1;
      let s = d.getDate();
      if (m < 10) m = "0" + m;
      if (s < 10) s = "0" + s;
      let datetime = y + "-" + m + "-" + s
      // this.$refs.Calendar.ChoseMonth(datetime,false);//false第二个参数表示不选中当天
      this.$refs.Calendar.ChoseMonth(datetime);
      let date = datetime.split('-')
      this.year = date[0]
      this.month = date[1]
      this.day = date[2]
      this.dateShow = false
      this.getPlanInfo(datetime);
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.getUserInfo();
        this.getPlanInfo();
        this.getSportdata();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  }
}
</script>
<style lang="less" scoped>
.braceletHome {
  width: 100%;
  min-height: 100vh;
  padding-top: 48px;
  background: url(~@/assets/bracelet_img/14.png);
  background-size: 120%;
  background-position: top;

  background-repeat: repeat-y;
  color: #ffffff;
  .topTitle {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: url(~@/assets/bracelet_img/20.png) no-repeat;
    background-size: 150% 100%;
    background-position: center;
    position: relative;
    img {
      width: 62px;
      height: 62px;
      margin-right: 8px;
      border-radius: 50%;
    }
    .info {
      max-width: 85%;
      .name {
        font-family: 'KenyanCoffeeRg-BoldItalic';
        font-size: 30px;
        max-width: 100%;
     //    overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .id {
        font-family: 'AeroMaticsItalic';
        font-size: 12px;
      }
    }
  }
  .tupian {
    width: 55%;
    height: 15px;
  }
  .box {
    padding: 15px 32px 90px;
    .rank_integral {
      width: 100%;
      height: 100px;
      background: url(~@/assets/bracelet_img/22.png) no-repeat;
      background-size: 100% 100%;
      border-radius: 15px;
      margin-bottom: 15px;
      .top {
        display: flex;
        justify-content: space-between;
        padding: 5px 5px 0 10px;
        .one {
          display: flex;
          .rank {
            font-family: '071-SSRuiFengTi';
            font-size: 24px;
            color: #6480f3;
            margin-right: 70px;
          }
          .label {
            height: 19px;
            line-height: 19px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            padding: 0 11px;
            color: #2b2b2b;
            background: #8ff9f6;
            border-radius: 10px;
          }
        }
        .two {
          display: flex;
          .integral {
            font-family: '071-SSRuiFengTi';
            font-size: 24px;
            color: #2b2b2b;
            margin-right: 18px;
          }
          .label {
            height: 19px;
            line-height: 19px;
            font-family: 'AeroMaticsItalic';
            font-size: 12px;
            color: #2b2b2b;
            padding: 0 11px;
            background: #fff;
            border-radius: 10px;
          }
        }
      }
      .btm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'KenyanCoffeeRg-BoldItalic';
        color: #2b2b2b;
        text-align: center;
        .left {
          width: 50%;
          font-size: 40px;
        }
        .right {
          width: 50%;
          font-size: 25px;
        }
      }
    }
    .distance {
      width: 100%;
      position: relative;
      margin-bottom: 15px;
      background-color: #ffffff;
      border-radius: 15px;
      padding: 17px 0 26px;
      .status {
        width: 55%;
        background-image: linear-gradient(115deg, #8ff9f6 0%, #ffffff 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 24px;
        color: #2b2b2b;
        padding: 2px 10px 5px;
        margin-bottom: 15px;
      }
      .bg {
        width: 100%;
        height: 101.25px;
      }
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -60px;
        .item {
          text-align: center;
          margin-bottom: 22.5px;
          .num {
            font-family: 'KenyanCoffeeRg-BoldItalic';
            font-size: 37.5px;
            color: #2b2b2b;
          }
          .row {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: '071-SSRuiFengTi';
            font-size: 22.5px;
            color: #6111a8;
            img {
              width: 15px;
              height: 15px;
              margin-right: 3.75px;
            }
          }
        }
        .btn {
          border-radius: 15px 3px 15px 3px;
          border: 2px solid #7f7f7f;
          padding: 3px 0px;
          font-family: '071-SSRuiFengTi';
          font-size: 30px;
          text-align: center;
          color: #2b2b2b;
          width: 200px;
        }
        .top_btn {
          background-color: #40e9ec;
          margin-bottom: 15px;
        }
        .btm_btn {
          background-color: #6480f3;
        }
      }
    }
    .datePreset {
      width: 100%;
      background-color: #ffffff;
      border-radius: 15px;
      padding: 17px 0 26px;
      .status {
        width: 55%;
        background-image: linear-gradient(115deg, #8ff9f6 0%, #ffffff 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 24px;
        color: #2b2b2b;
        padding: 2px 10px 5px;
      }
      .box {
        padding: 17px 15px 26px;
        .calendar {
          width: 100%;
          box-shadow: 0px 0px 11.25px 3px rgba(0, 0, 0, 0.1);
          border-radius: 13px;
          .braceletHomeTitle {
            padding: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'AeroMaticsDisplayBold';
            font-size: 22px;
            color: #2b2b2b;
            border-bottom: 2px solid #94f9f6;
            img {
              width: 18.75px;
              height: 12px;
            }
          }
          .wh_container {
            /deep/.wh_content_all {
              background-color: #fff;
              border-radius: 0 0 13px 13px;
            }
            /deep/.wh_item_date {
              color: #2b2b2b;
            }
            // /deep/.wh_item_date:hover{
            //   background-color: #7741FA;
            //   border-radius: 7.5px;
            //   color: #fff;
            // }
            /deep/.wh_top_tag {
              color: #2b2b2b;
            }
            /deep/.wh_top_changge li {
              color: #2b2b2b;
            }
            /deep/.wh_top_changge {
              display: none;
            }
            /deep/.wh_other_dayhide {
              color: #bfbfbf;
            }
            /deep/.wh_chose_day {
              background-color: #7741fa;
              border-radius: 7.5px;
              color: #fff;
            }
            /deep/.wh_isToday {
              background-color: #8ff9f6;
              border-radius: 7.5px;
            }
          }
        }
      }
      .time {
        padding-left: 25px;
        .braceletHomeTitle {
          font-family: 'AeroMaticsDisplayBold';
          font-size: 24px;
          color: #2b2b2b;
          margin-bottom: 18px;
        }
        .item {
          display: flex;
          .line {
            width: 5px;
            height: 76px;
            background-color: #0ed5d9;
            margin-right: 14px;
          }
          .right {
            .text {
              font-family: 'TsangerYuYangT-W05';
              font-size: 18px;
              color: #2b2b2b;
            }
            .dt {
              font-family: 'AeroMaticsItalic';
              font-size: 18px;
              color: #6111a8;
            }
          }
        }
        .mar_btm_15 {
          margin-bottom: 15px;
        }
      }
      .report {
        margin-top: 10px;
        text-align: end;
        padding: 5px 15px;
        background-image: linear-gradient(115deg, #ffffff 0%, #7736fe 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 20px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .footBtm {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 22.5px;
    .left {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 0 3px 3px 0;
      padding: 3px 3px 3px 0;
      margin-right: 3px;
      .box {
        padding: 3px 10px 3px 7.5px;
        background-color: rgba(43, 43, 43, 0.9);
        border-radius: 0 3px 3px 0;
        border: solid 2px #7736fe;
        border-left: none;
        display: flex;
        img {
          width: 26px;
          height: 18.75px;
        }
      }
    }
    .right {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 3px 26.25px 3px 3px;
      padding: 3.75px 20px 3.75px 7.5px;
      display: flex;
      align-items: center;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 18.75px;
      color: #2b2b2b;
      .ask {
        background-color: #5c65cb;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 0;
        width: 140px;
        text-align: center;
        margin-right: 7.5px;
      }
      .invite {
        background-image: linear-gradient(#a7f264, #a7f264),
          linear-gradient(#5c65cb, #5c65cb);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 0;
        width: 140px;
        text-align: center;
      }
    }
  }
}
.navto {
  position: absolute;
  right: 0;
  width: 33px !important;
  height: 33px !important;
}
.navto1 {
  position: absolute;
  right: 35px;
  width: 33px !important;
  height: 33px !important;
}
.pop-up {
  color: #000;
  border-radius: 10px;
  width: 280px;

  .pop-up-box {
    padding: 10px;
    .pop-box-radio {
      padding: 5px;
    }
  }
  .pop-up-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    .pop-btn-btn {
      border: 0;
      background-color: #fff;
      width: 40%;
    }
    .btn-determine-col {
      color: #ee0a24;
    }
    .btn-cancel-col {
      color: #b6b6b6;
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  /deep/.van-popup--bottom {
    width: 430px;
    left: calc(50% - 215px);
  }
}
</style>
